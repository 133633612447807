<template>
  <div>
    <div class="row page-title">
      <label class="Agency-Name-HFM-Co">
        {{ Request.VendorBusinessName}} </label>
    </div>
    <div class="row icreview-component">
      <prodexp-amend-overview class="col-lg-8" :ID="id" v-on:LoadProdEXPAmendDetails="LoadVendorRequestDetails"></prodexp-amend-overview>
      <div class="col-lg-4"  v-if="IsDataLoaded">
        <div class="py-2 px-1">
          <b-alert :show="!IsApproveEnabled" class="alert-info-new position-fixed fixed-top m-0 rounded-0" variant="info" dismissible>
              The Request is already completed.
          </b-alert>
          <p class="approve-reject-label px-3 pt-2 pb-1">
            Complete The Production Vendor
          </p>
          <p class="approve-reject-content px-3 py-2">
            When approved, this request will be completed.
          </p>
          <div class="px-3">
            <label for="completedcode">Type of Amendment / Comment</label>
            <b-input
              v-bind:class="{
                errorborder: $v.CompletedCode.$invalid && $v.CompletedCode.$dirty,
                noterrorborder: !$v.CompletedCode.$invalid,
              }"
              @blur="$v.CompletedCode.$touch()"
              @focus="$v.CompletedCode.$reset()"
              id="completedcode" name="completedcode" v-model="CompletedCode" placeholder="Enter Type of Amendment / Comment" />
            <div class="error-message-format required-field" v-if="!$v.CompletedCode.required && $v.CompletedCode.$dirty">
              Required field
            </div>
          </div>
          <div class="text-right pb-3 pr-3" style="width: 100%;padding-top: 10px;">
            <button v-if="IsApproveEnabled" class="btn custom-button btn-primary mx-2" @click="Approve()">
              Complete
            </button>
            <button v-else class="btn custom-button btn-primary mx-2" disabled="disabled"
              title="The request is already completed.">
              Complete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { required } from "vuelidate/lib/validators";
import * as status from "../../../utilities/VendorWorkflowStates";

export default {
  name: 'Complete',
  validations: {
    CompletedCode: {
      required,
    },
  },
  data() {
    return {
      Request: {},
      CompletedCode: "",
    };
  },
  props: ["id"],
  mounted() {
    if (!this.canCompleteVendorRequest) {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    }
  },
  computed: {
    canCompleteVendorRequest() {
      return this.$store.getters.canCompleteVendorRequest;
    },
    IsApproveEnabled(){
      return this.Request && this.Request.Status == status.FinancialSystemApproval;
    },
    IsDataLoaded() {
      return this.Request && !(this.Request.Status == "" || this.Request.Status == null);
    }
  },
  methods: {
    LoadVendorRequestDetails(vendorRequestDetails){
      this.Request = vendorRequestDetails;
    },
    GetData() {
      return {
        ID: parseInt(this.id),
        ActiveTaskID: this.Request.ActiveTaskID,
        ProjectID: this.Request.OmniflowProjectID,
        ProcessID: this.Request.OmniflowProcessID,
        CompletedCode: this.CompletedCode
      };
    },
    Approve() {
      this.$store
        .dispatch("prodexpamend/CompleteRequest", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The request ${this.Request.VendorBusinessName} has been approved`
          );
          this.$router.push("/VendorRequests");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while approving request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while approving request", ex);
          }
        });
    },
    SetErrorMessage(message, ex) {
      store.dispatch(
        "progress/setErrorMessage",
        `${message} ${this.Request.VendorBusinessName}. The error is ${ex}`
      );
    },
  },
};
</script>